<template>
  <div v-if="statement" class="ml-2 mr-1">
      <b-col>
        <b-form-group>
          <template #label
            ><span class="text-danger">*</span> 适用：</template
          >
          <b-form-checkbox-group
            v-model="aryCompliance"
            :options="complianceOptions"
            disabled-field="notEnabled"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 编号：</template>
          <b-form-input v-model="statement.issueNo" placeholder="请输入编号" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 标题：</template>
          <b-form-input v-model="statement.title" placeholder="请输入标题" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 概述：</template>
           <b-form-textarea
              id="h-abstract"
              v-model="statement.abstract"
              placeholder="概述"
              rows="1"/>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label>
            <span class="text-danger">*</span>声明文档：
          </template>

          <b-form-file
            v-model="attachmentArray"
            size="sm"
            accept=".docx"
            placeholder="请选择文件"
            drop-placeholder="Drop file here..."
            @input="loadFileContent"
          />
        </b-form-group>
      </b-col>

      <b-col v-if="htmlContent">
        <b-form-group label="预览：">
          <b-progress v-model="toPDFProgress" max="100" class="mb-1" />
          <quill-editor
            v-model="htmlContent"
            toolbar="minimal"
            :options="quillEditorOption"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="提示：">
          <b-link
            href="https://services.zhenhecai.com/ent/files/template/Web网站隐私声明模版V1.0.docx"
          >
            点击获取隐私声明模板
          </b-link>
        </b-form-group>
      </b-col>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";

import html2PDF from "html-pdf-adaptive";
import { convertToHtml } from "mammoth";

import { mapState } from "vuex";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "StatementEdit",

  props: ["obj", "toSave"],

  components: {
    BCard,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
    quillEditor,
    html2PDF,
  },

  data() {
    return {
      statement: null,

      aryCompliance: [],
      attachmentArray: [],
      htmlContent: null,
      toPDFProgress: 0,

      quillEditorOption: { theme: "bubble", placeholder: "在这里输入内容" },
    };
  },

  computed: {
    ...mapState({
      complianceOptions: (state) => state.appOptions.complianceOptions,
      industryOptions: (state) => state.appOptions.industryOptions,
    }),
  },

  mounted() {
    this.cloneObject();
  },

  watch: {
    obj: {
      handler(val) {
        this.cloneObject();
      },
      deep: true,
    },

    toSave: {
      handler(val) {
        console.log("toSave", val);
        if (val) {
          // debugger
          this.statement.compliance = this.aryCompliance.join(",");
          this.$emit("save", this.statement);
        }
      },
      deep: true,
    },
  },

  methods: {
    cloneObject() {
      this.statement = JSON.parse(JSON.stringify(this.obj));
      this.aryCompliance = this.statement ? this.statement.compliance.split(",") : [];
    },

    loadFileContent() {
      let file = this.attachmentArray;
      if (!file) {
        this.htmlContent = null;
        return;
      }

      if (file.name.substr(-5, 5)!=".docx"){
        console.log("文件类型不正确");
        return;
      }

      // this.readFileInputEventAsArrayBuffer(file, this.convertToPdf);
      this.readFileInputEventAsArrayBuffer(file, null);
      this.uploadFile(file, (attachment) => {
        this.statement.attachment = attachment.url;
        this.statement.displayFile = attachment.pdf;
      });
    },

    // convertToPdf(arrayBuffer) {
    //   convertToHtml({ arrayBuffer: arrayBuffer })
    //     .then((result) => {
    //       // console.log(result.value);
    //       this.htmlContent = result.value;

    //       this.$nextTick(() => {
    //         // this.$refs.quillEditor.quill.focus();
    //         const el = document.querySelector(".ql-editor");
    //         // console.log(el);
    //         html2PDF(el, {
    //           pagesplit: true,
    //           orientation: "p",
    //           unit: "pt",
    //           format: "a4",
    //           outputType: "file", // save, bloburl, file
    //           useCORS: true,

    //           onProgress: (percent, pageNum, bloburl) => {
    //             this.toPDFProgress = percent;
    //             // console.log(`${percent}%, ${pageNum}, ${bloburl}`);
    //           },
    //           onComplete: (pdfFile) => {
    //             // console.log(pdfFile);
    //             this.toPDFProgress = 100;
    //             this.uploadFile(pdfFile, (url) => {
    //               this.statement.displayFile = url;
    //             });
    //           },
    //         });
    //       });
    //     })
    //     .done();
    // },

    // convertToPdf(arrayBuffer) {
    //   const zip = new PizZip(content);
    //   const doc = new Docxtemplater(zip, {
    //     paragraphLoop: true,
    //     linebreaks: true,
    //   });
    //   const out = doc.getZip().generate({
    //     type: "blob",
    //     mimeType:
    //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   });
    //   // Output the document using Data-URI
    //   saveAs(out, "output.docx");
    // },

    readFileInputEventAsArrayBuffer(file, callback) {
      var reader = new FileReader();

      reader.onload = function (loadEvent) {
        var arrayBuffer = loadEvent.target.result;
        if (callback) callback(arrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    },

    // upload
    uploadFile(file, callback) {
      // let file = this.attachmentArray;
      console.log("org file", file);
      if (file) {
        let formData = new FormData();
        formData.append("uploadFile", file);
        let apiPoint = "https://services.zhenhecai.com/it/upload?ext=docx&needpdf=true";
        this.$http
          .post(apiPoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            // Gets the file information
            let attachment = res.data.data;
            console.log("upload result", attachment);
            // this.currentItem.attachment = attachment.url;
            if (callback) callback(attachment);
          })
          .catch((err) => {
            // debugger;
          });
      }
    },
  },
};
</script>

<style>
</style>